import {take, put, fork, all} from 'redux-saga/effects'
import {
    apiError,
    apiRequest,
    apiSuccess,
    DO_LOGIN, doStateLogout,
    FIND_SNIPPETS,
    FIND_SNIPPETS_BY_TAG, INITIALIZE_TOKENS,
    LOAD_MORE_SNIPPETS, loginFailed, loginSuccess, LOGOUT
} from "./actions";
import {doLoginAPI, getCurrentUser, getSnippetsAPI, getTagAPI, logoutUserAPI} from "./api";
import {currentSearchType, getSearchTagValue, getSearchValue, getSnippetCount, isLogin} from "./selectors";
import {select} from "@redux-saga/core/effects";

export default function* rootSaga() {
    yield all([
        fork(watchGetList),
        fork(watchGetTag),
        fork(watchLoadMore),
        fork(watchDoLogin),
        fork(watchInitializeTokens),
        fork(watchDoLogout)
    ]);
}

function* watchGetList() {
    while (true) {
        const {payload} = yield take(FIND_SNIPPETS);
        yield put(apiRequest());

        const {response, error} = yield getSnippetsAPI(payload);
        if (response) {
            yield put(apiSuccess(response));
        } else {
            yield put(apiError(error));
        }
    }
}

function* watchGetTag() {
    while (true) {
        const {payload} = yield take(FIND_SNIPPETS_BY_TAG);
        yield put(apiRequest());

        const {response, error} = yield getTagAPI(payload);
        if (response) {
            yield put(apiSuccess(response));
        } else {
            yield put(apiError(error));
        }
    }
}

function* watchLoadMore() {
    while (true) {
        yield take(LOAD_MORE_SNIPPETS);
        const offset = yield select(getSnippetCount);
        const searchType = yield select(currentSearchType);
        yield put(apiRequest());

        let result;
        if ('tag' === searchType) {
            const tag = yield select(getSearchTagValue);
            result = yield getTagAPI(tag, offset);
        } else {
            const search = yield select(getSearchValue);
            result = yield getSnippetsAPI(search, offset);
        }

        if (result.response) {
            const response = {list: result.response, paginate: true}
            yield put(apiSuccess(response));
        } else {
            const error = {...result.error, paginate: true}
            yield put(apiError(error));
        }
    }
}

function* watchInitializeTokens() {
    while (true) {
        yield take(INITIALIZE_TOKENS);
        const isUserLogin = yield select(isLogin);
        if (isUserLogin) {
            continue;
        }

        let token = localStorage.getItem('access_token');
        let refreshToken = localStorage.getItem('refresh_token');
        if (!token || !refreshToken) {
            continue;
        }

        yield put(apiRequest());
        const {response, error} = yield getCurrentUser(token, refreshToken);
        if (response) {
            const {data: {firstname, lastname}} = response;
            if (response.headers['x-token']) {
                token = response.headers['x-token'];
                refreshToken = response.headers['x-refresh-token'];

                localStorage.setItem('access_token', token);
                localStorage.setItem('refresh_token', refreshToken);
            }

            const tokens = {token, refreshToken};
            const user = {firstname, lastname};
            yield put(loginSuccess(tokens, user));
        } else {
            yield put(loginFailed(error));
        }
    }
}

function* watchDoLogin() {
    while (true) {
        const {username, password} = yield take(DO_LOGIN);

        yield put(apiRequest());
        const {response, error} = yield doLoginAPI(username, password);
        if (response) {
            const {token, refreshToken, user: {firstname, lastname}} = response;

            yield localStorage.setItem('access_token', token);
            yield localStorage.setItem('refresh_token', refreshToken);

            const tokens = {token, refreshToken};
            const user = {firstname, lastname};
            yield put(loginSuccess(tokens, user));
        } else {
            yield put(loginFailed(error));
        }
    }
}

function* watchDoLogout() {
    while (true) {
        yield take(LOGOUT);
        const isUserLogin = yield select(isLogin);
        if(!isUserLogin) {
            continue;
        }

        let token = yield localStorage.getItem('access_token');
        let refreshToken = yield localStorage.getItem('refresh_token');
        if (!token || !refreshToken) {
            yield localStorage.removeItem('refresh_token');
            continue;
        }

        yield put(apiRequest());
        yield logoutUserAPI(token, refreshToken);
        yield localStorage.removeItem('access_token');
        yield localStorage.removeItem('refresh_token');
        yield put(doStateLogout());
    }
}