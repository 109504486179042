export const FIND_SNIPPETS = 'FIND_SNIPPETS';
export const FIND_SNIPPETS_BY_TAG = 'FIND_SNIPPETS_BY_TAG';
export const LOAD_MORE_SNIPPETS = 'LOAD_MORE_SNIPPETS';

export const API_REQUEST = 'API_REQUEST';
export const API_SUCCESS = 'API_SUCCESS';
export const API_ERRORED = 'API_ERRORED';

export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE';
export const SET_SEARCH_TAG_VALUE = 'SET_SEARCH_TAG_VALUE';

export const DO_LOGIN = 'DO_LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const INITIALIZE_TOKENS = 'INITIALIZE_TOKENS';
export const LOGOUT = 'LOGOUT';
export const STATE_LOGOUT = 'STATE_LOGOUT';

export const findSnippets = (search = '') => ({type: FIND_SNIPPETS, payload: search});
export const findSnippetsByTag = (tag = '') => ({type: FIND_SNIPPETS_BY_TAG, payload: tag});
export const loadMoreSnippets = () => ({type: LOAD_MORE_SNIPPETS});

export const apiRequest = () => ({type: API_REQUEST});
export const apiSuccess = (snippets = []) => ({type: API_SUCCESS, payload: snippets});
export const apiError = (error = null) => ({type: API_ERRORED, payload: error});

export const setSearchValue = (value = '') => ({type: SET_SEARCH_VALUE, payload: value});
export const setSearchTagValue = (value = '') => ({type: SET_SEARCH_TAG_VALUE, payload: value});

export const doLogin = (username, password) => ({type: DO_LOGIN, username, password});
export const loginSuccess = (tokens, user) => ({type: LOGIN_SUCCESS, tokens, user});
export const loginFailed = (error = null) => ({type: LOGIN_FAILED, error});
export const initializeTokens = () => ({type: INITIALIZE_TOKENS});
export const doLogout = () => ({type: LOGOUT});
export const doStateLogout = () => ({type: STATE_LOGOUT});