import {applyMiddleware, createStore} from "redux";
import reducer from "./reducer";
import createSagaMiddleware from 'redux-saga'
import rootSaga from "./sagas";
import { composeWithDevTools } from 'redux-devtools-extension';

const composeEnhancers = composeWithDevTools({
    // Specify custom devTools options
});

const sagaMiddleware = createSagaMiddleware();
const store = createStore(reducer, composeEnhancers(
    applyMiddleware(sagaMiddleware)
));
sagaMiddleware.run(rootSaga);

export default store;