import React from 'react';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import {Provider} from "react-redux";

import CodeList from './components/code-list';
import Search from "./components/search";
import store from "./store/store";

import 'bulma/css/bulma.css';
import './css/app.css';
import Login from "./components/login";

function App() {

    const Home = React.memo(() => (
        <>
            <Search/>
            <CodeList/>
        </>
    ));

    const routes = (
        <Switch>
            <Route path='/' component={Home} exact/>
            <Route path='/login' component={Login}/>
        </Switch>
    );

    return (
        <Router>
            <Provider store={store}>
                <div className="App">
                    {routes}
                </div>
            </Provider>
        </Router>
    );
}

export default App;
