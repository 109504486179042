import React from "react";
import Snippet from "./snippet";

import '../css/code-list.css';
import {useSelector} from "react-redux";
import LoadMore from "./loadmore";

const CodeList = React.memo(
    () => {
        const snippets = useSelector(store => store.snippets);
        const error = useSelector(store => store.error);
        const isLoading = useSelector(store => store.isLoading);

        const loadMoreButton = (
            <div className="has-text-centered">
                <LoadMore/>
            </div>
        );

        return (
            <div className="code-list">
                {
                    snippets.length > 0 ? snippets.map((snippet, index) =>
                        <Snippet
                            key={index}
                            title={snippet.title}
                            id={snippet.id}
                            code={snippet.body}
                            tags={snippet.tags}/>
                    ) : error && !isLoading && <h1 className="has-text-centered has-text-danger title is-4">{error}</h1>
                }
                {snippets.length > 0 ? loadMoreButton : null}
            </div>
        );
    }
);

export default CodeList;