import React, {useCallback, useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";

import '../css/search.css';
import {findSnippets, findSnippetsByTag, setSearchTagValue, setSearchValue} from "../store/actions";
import {getSearchTagValue, getSearchValue} from "../store/selectors";
import LoginHeader from "./login-header";

const Search = React.memo(
    props => {

        const dispatch = useDispatch();
        const searchValue = useSelector(getSearchValue);
        const searchTagValue = useSelector(getSearchTagValue);

        const onSearchChanged = useCallback(event => {
            dispatch(setSearchValue(event.target.value));
        }, [dispatch]);
        const onSearchTagChanged = useCallback(event => {
            dispatch(setSearchTagValue(event.target.value));
        }, [dispatch]);

        useEffect(() => {
            if(searchTagValue) {
                return;
            }

            const timeout = setTimeout(() => {
                dispatch(findSnippets(searchValue));
            }, 500);

            return () => clearInterval(timeout);
            // eslint-disable-next-line
        }, [searchValue, dispatch]);

        //Don't fire Tag Search API Call on initial render
        const firstUpdate = useRef(true);
        useEffect(() => {
            if(firstUpdate.current || searchValue) {
                firstUpdate.current = false;
                return;
            }

            const timeout = setTimeout(() => {
                dispatch(findSnippetsByTag(searchTagValue));
            }, 500);

            return () => clearInterval(timeout);
            // eslint-disable-next-line
        }, [searchTagValue, dispatch]);

        const isLoading = useSelector(store => store.isLoading);

        return (
            <div className="search-box">
                <LoginHeader />
                <div className="search-box-inner">
                    <div className="columns">
                        <div className="column">
                            <div className="field">
                                <div className="control">
                                    <input type="text" onChange={onSearchChanged} value={searchValue}
                                           className="search-bar input"
                                           placeholder="Search for code"/>
                                </div>
                            </div>
                        </div>
                        <div className="column">
                            <div className="field">
                                <div className="control">
                                    <input type="text" onChange={onSearchTagChanged} value={searchTagValue}
                                           className="search-bar input"
                                           placeholder="Search for tag"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    {isLoading && <progress className="progress is-small is-primary" max="100">15%</progress>}
                </div>
            </div>
        );
    }
);

export default Search;