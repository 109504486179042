import {
    API_ERRORED,
    API_REQUEST,
    API_SUCCESS,
    LOGIN_FAILED,
    LOGIN_SUCCESS,
    SET_SEARCH_TAG_VALUE,
    SET_SEARCH_VALUE, STATE_LOGOUT
} from "./actions";

const initialState = {
    snippets: [],
    page: 1,
    isLoading: false,
    search: '',
    error: null,
    searchTag: '',

    token: '',
    refreshToken: '',
    userFirstName: '',
    userLastName: ''
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case API_REQUEST:
            return {...state, isLoading: true, error: null}
        case API_SUCCESS:
            return handleApiResponse(state, action.payload);
        case API_ERRORED:
            return handleApiError(state, action.payload);
        case SET_SEARCH_VALUE:
            return {...state, search: action.payload, searchTag: ''};
        case SET_SEARCH_TAG_VALUE:
            return {...state, search: '', searchTag: action.payload};
        case LOGIN_SUCCESS:
            const {user: {firstname, lastname}, tokens: {token, refreshToken}} = action;
            return {...state, isLoading: false, userFirstName: firstname, userLastName: lastname, token, refreshToken};
        case LOGIN_FAILED:
            return handleApiError(state, action.error);
        case STATE_LOGOUT:
            return {...state, isLoading: false, error: false, token: '', refreshToken: '', userFirstName: '', userLastName: ''};
        default:
            return state;
    }
};

const handleApiResponse = (state, response) => {
    if (response.paginate && true === response.paginate) {
        return {...state, isLoading: false, snippets: [...state.snippets, ...response.list]}
    }

    return {...state, isLoading: false, snippets: response}
}

const handleApiError = (state, error) => {
    const errorMessage = error.response && error.response.data && error.response.data.error ? error.response.data.error : (typeof error === 'string' ? error : error.toString());
    if (error.paginate && true === error.paginate) {
        return {...state, isLoading: false, error: errorMessage}
    }

    return {...state, isLoading: false, error: errorMessage, snippets: []}
}

export default reducer;