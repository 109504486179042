import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {loadMoreSnippets} from "../store/actions";

const LoadMore = React.memo(
    props => {
        const dispatch = useDispatch();
        const onButtonClick = useCallback(() => {
            dispatch(loadMoreSnippets());
        }, [dispatch]);

        const isLoading = useSelector(store => store.isLoading);
        const error = useSelector(store=>store.error);

        return (
            <button disabled={isLoading || !!error} onClick={onButtonClick}
                    className="load-more button is-medium is-danger is-center">{error ? error: 'Load More'}</button>
        );
    }
);

export default LoadMore;