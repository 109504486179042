import React from "react";

import '../css/snippet.css';

const Snippet = React.memo(
    props => {

        return (
            <div className="snippet card">
                <div className="card-content">
                    <a href={"http://localhost:8080/snippet/" + props.id}>
                        {props.title && <h2 className="title is-4">{props.title}</h2>}
                    </a>
                    <code>{props.code}</code>
                    <div className="tags snippet-tags">
                        {props.tags && props.tags.map((tag, index) => (
                            <div className="is-danger tag" key={index}>{tag.name}</div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
);

export default Snippet;