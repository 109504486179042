import React, {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {apiRequest, doLogout, initializeTokens} from "../store/actions";
import {getUser, isLogin} from "../store/selectors";
import {useHistory} from "react-router-dom";

const LoginHeader = React.memo(props => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(initializeTokens());
    }, [dispatch]);

    const isUserLogin = useSelector(isLogin);
    const {firstname, lastname} = useSelector(getUser);

    const history = useHistory();
    const onButtonClick = useCallback(e => {
        if(isUserLogin) {
            dispatch(doLogout());
            return;
        }

        dispatch(apiRequest());
        history.push('/login');
    }, [history, isUserLogin, dispatch]);

    return (
        <div className="login-header">
            {isUserLogin && <span>Welcome {firstname + ' ' + lastname}</span>}
            <button onClick={onButtonClick} className="button is-danger is-small">{isUserLogin ? 'Logout' : 'Login'}</button>
        </div>
    );
});

export default LoginHeader;