import axios from 'axios';

const url = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? 'http://localhost:8080' : window.location.origin;

export const getSnippetsAPI = async (search = '', offset = 0) => {
    try {
        const endpoint = new URL(url + '/documents/list');
        '' !== search && endpoint.searchParams.append('search', search);
        0 !== offset && endpoint.searchParams.append('offset', offset.toString());

        const response = await axios.get(endpoint.href);
        if (200 !== response.status) {
            return {response: null, error: new Error('Status Code: ' + response.status)};
        }

        return {response: response.data.list, error: null};
    } catch (err) {
        return {response: null, error: err}
    }
};

export const getTagAPI = async (tag = '', offset = 0) => {
    try {
        const endpoint = new URL(url + '/documents/list');
        '' !== tag && endpoint.searchParams.append('tag', tag);
        0 !== offset && endpoint.searchParams.append('offset', offset.toString());

        const response = await axios.get(endpoint.href);
        if (200 !== response.status) {
            return {response: null, error: new Error('Status Code: ' + response.status)};
        }

        return {response: response.data.list, error: null};
    } catch (err) {
        return {response: null, error: err}
    }
};

export const doLoginAPI = async (username, password) => {
    if (!username || !password) {
        return {response: null, error: (new Error('Invalid Credentials'))};
    }

    try {
        const endpoint = new URL(url + '/users/login');

        const response = await axios.post(endpoint.href, {username, password});

        if (200 !== response.status) {
            return {response: null, error: new Error('Status Code: ' + response.status)};
        }

        return {response: response.data, error: null};
    } catch (err) {
        return {response: null, error: err};
    }
}

export const getCurrentUser = async (token, refreshToken) => {
    try {
        const endpoint = new URL(url + '/users/current');
        endpoint.searchParams.append('timestamp', (new Date()).getTime().toString());
        const headers = {
            'x-token': token,
            'x-refresh-token': refreshToken
        }
        const response = await axios.get(endpoint.href, {headers});

        if (200 !== response.status) {
            return {response: null, error: new Error('Status Code: ' + response.status)};
        }

        return {response: response, error: null};
    } catch (err) {
        return {response: null, error: err};
    }
}

export const logoutUserAPI = async (token, refreshToken) => {
    try {
        const endpoint = new URL(url + '/users/logout');
        const headers = {
            'x-token': token,
            'x-refresh-token': refreshToken
        }
        const response = await axios.get(endpoint.href, {headers});
        if (200 !== response.status) {
            return {response: null, error: new Error('Status Code: ' + response.status)};
        }

        return {response: response, error: null};
    } catch (err) {
        return {response: null, error: err};
    }
}
