import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {apiRequest, doLogin, initializeTokens} from "../store/actions";
import '../css/login.css';
import {isLogin} from "../store/selectors";
import {useHistory} from "react-router";

const Login = React.memo(
    props => {
        const [usernameValue, setUsernameValue] = useState('');
        const [passwordValue, setPasswordValue] = useState('');

        const onUsernameChanged = useCallback((e) => setUsernameValue(e.target.value), []);
        const onPasswordChanged = useCallback((e) => setPasswordValue(e.target.value), []);

        const dispatch = useDispatch();
        const onKeyPressed = useCallback(
            (e) => 13 === e.keyCode && dispatch(doLogin(usernameValue, passwordValue)),
            [dispatch, usernameValue, passwordValue]
        );
        const onLoginClick = useCallback(
            () => dispatch(doLogin(usernameValue, passwordValue))
            , [dispatch, usernameValue, passwordValue]
        );

        const isLoggedIn = useSelector(isLogin);
        const error = useSelector(store => store.error);
        const history = useHistory();

        useEffect(() => {
            dispatch(initializeTokens());
        }, [dispatch]);

        useEffect(()=>{
            if(isLoggedIn) {
                dispatch(apiRequest());
                history.push('/');
            }
        }, [isLoggedIn, history, dispatch]);

        return (
            <div className="login-page">
                <div className="login-internal card">
                    <div className="card-content">
                        <div className="field">
                            <div className="control">
                                <input className="Text input" type="text" value={usernameValue}
                                       onChange={onUsernameChanged} onKeyPress={onKeyPressed}
                                       placeholder="Username"/>
                            </div>
                        </div>
                        <div className="field">
                            <div className="control">
                                <input className="Text input" type="password" value={passwordValue}
                                       onChange={onPasswordChanged} onKeyPress={onKeyPressed}
                                       placeholder="Password"/>
                            </div>
                        </div>
                        <button className="button is-danger" onClick={onLoginClick}>Login</button>
                        <br/><br/>
                        {error && <div className="notification is-danger is-light">{error}</div>}
                    </div>
                </div>
            </div>
        );
    }
);

export default Login;